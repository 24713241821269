//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { account_service } from "@/services";
import BaseLayout from "@/layouts/Base";
export default {
  metaInfo: {
    title: "Account Verification",
  },
  components: { BaseLayout },
  data() {
    return {
      email: "",
      resend: false,
      success: undefined,
      message: "Please wait! verifying your account",
      loading: true,
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  mounted() {
    this.verify_account();
  },
  methods: {
    enter(el) {
      this.$gsap.from(el, {
        scale: 0,
        ease: "back",
        duration: 0.6,
      });
    },
    resend_activation() {
      account_service
        .post({ email: this.email }, "resend")
        .then(() => {
          this.$q.notify({
            icon: "mdi-check",
            color: "positive",
            duration: 5000,
            textColor: "white",
            message:
              "If your account exists on our system, you will receive an email with an activation link.",
          });
        })
        .catch((error) => {
          var message = "";
          if (error.response && error.response.data)
            message = this.first_of(error.response.data.email);
          this.$q.notify({
            icon: "mdi-alert-circle",
            color: "negative",
            duration: 5000,
            textColor: "white",
            message: message || this.decode_error_message(error),
          });
        });
    },
    verify_account() {
      account_service
        .get({}, `${this.token}/verify`)
        .then((data) => {
          this.success = data.success;
          this.loading = false;

          if (data.success) {
            this.message = "Account Activated! Please login to proceed.";
          } else {
            this.message =
              "Account activation failed! Your activation link has expired.";
          }
        })
        .catch((error) => {
          this.message = this.decode_error_message(error);
          this.loading = false;
          this.success = false;
        });
    },
  },
};
